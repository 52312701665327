/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
import { Pagination, Row, Col } from 'antd'

import NewsItem from '../components/antd/NewsItem'
import Layout from '../components/layout'
import '../components/antd/less/antMotionStyle.less'
import SEO from '../components/seo'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Home extends React.Component {
  constructor(...props) {
    super(...props)
    this.state = {
      isMobile,
      current: 1,
      showNewsData: [],
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
    this.getNewsData(1)
  }

  onChange = page => {
    this.setState({ current: page })
    this.getNewsData(page)
  }

  getNewsData = current => {
    const {
      data: { strapi = {} },
    } = this.props
    const { news = [] } = strapi
    const newNews = news.sort((a, b) => {
      return Date.parse(b.publish_date) - Date.parse(a.publish_date)
    })
    this.setState({
      showNewsData: newNews.slice((current - 1) * 8, (current - 1) * 8 + 8),
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { news = [], products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={5}
        products={products}
        hasBg={true}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='新闻动态' />
        <div className='news-list-wrap'>
          <div className='main-con'>
            <Row className='news-list'>
              {this.state.showNewsData.map(newItem => (
                <Col className='news-item-wrap' key={newItem.id} md={6} xs={24}>
                  <NewsItem
                    id='News_0'
                    key='News_0'
                    newsitem={newItem}
                    isMobile={this.state.isMobile}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <div className='pagination-wrap'>
            <Pagination
              pageSize={8}
              current={this.state.current}
              onChange={this.onChange}
              total={news.length}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    strapi {
      news(sort: "publish_date") {
        id
        title
        publish_date
        short_description
        link
        tags {
          id
          key
        }
        img {
          url
        }
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
